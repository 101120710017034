import {
    rep_data
  } from "../../js/path";
  
  export default {
    data() {
      return {
        pageNo: null,
        table_header: [],
        campaign_id: null,
        fields: [
          {
            key: "id",
            label: "ID",
            sortable: true,
          },
          {
            key: "full_name",
            label: "Rep name"
          },
          {
            key: "username",
            label: "User Name"
          },
          {
            key: "edit",
          },
        ],
        params: "",
        filter: null,
        filterOn: [],
        tableData: [],
        sortBy: "id",
        sortDesc: true,
        key: 0,
        activeTab: "all",
        currentPage: 1,
        form: {
          fname: "",
          lname: "",
          username:"",
          password: ""
        },
      };
    },
    computed: {
      
    },
    methods: {
      search(event) {
        if (this.filter.length > 1) {
          if (event.keyCode == 13) {
            this.fetchData(this.activeTab);
          }
        } else if (this.filter.length == 0) this.fetchData(this.activeTab);
      },
      searchFor() {
        if (this.filter.length > 0) {
          this.fetchData(this.activeTab);
        } else {
          this.fetchData(this.activeTab);
        }
      },
      filterPage() {
        if (this.pageNo.length > 0) {
          this.params = `&page=${this.pageNo}`;
          this.fetchData(this.activeTab);
          this.currentPage = this.pageNo;
        }
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      async fetchData(txt) {
        let url = null;
        if (txt == "active") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
              rep_data.mrAppRepDataUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = rep_data.mrAppRepDataUrl + "?filter=" + txt;
          }
        } else if (txt == "trash") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
              rep_data.mrAppRepDataUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = rep_data.mrAppRepDataUrl + "?filter=" + txt;
          }
        } else if (txt == "all") {
          this.activeTab = txt;
          if (this.filter != null && this.filter != "") {
            url =
              rep_data.mrAppRepDataUrl +
              "?filter=" +
              txt +
              "&search=" +
              this.filter;
          } else {
            url = rep_data.mrAppRepDataUrl + "?filter=" + txt;
          }
        }
        url += this.params;
        this.$store.commit("loader/updateStatus", true);
        try {
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            this.tableData = responseData;
          }
        } catch (err) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
        this.key += 1;
      },
      async fetchReps(id) {
        this.$store.commit("toast/updateStatus", true);
        try {
          let url = rep_data.mrAppRepDataUrl + "/" + id;
          const data = await this.getRequest(url);
          if (data.status) {
            const responseData = data.response;
            if (responseData.fname != "" && responseData.fname != null) {
              this.form.fname = responseData.fname;
            }
            if (
              responseData.lname != "" &&
              responseData.lname != null
            ) {
              this.form.lname = responseData.lname;
            }
            if (responseData.username != "" && responseData.username != null) {
              this.form.username = responseData.username;
            }
            this.campaign_id = responseData.id;
          }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Fetching error!",
          });
        }
        this.$store.commit("toast/updateStatus", false);
      },
      async submitData() {
        try {
          this.submitted = true;
          this.$v.$touch();
          if (this.$v.$invalid) {
            // window.scrollTo({
            //    top: 0,
            //    behavior: "smooth",
            // });
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "error",
              title: "Please Fill The Required Fields",
            });
            return false;
          }
          this.$store.commit("loader/updateStatus", true);
          let url = rep_data.mrAppRepDataUrl;
          if (this.$route.name == "edit-rep") {
            url = rep_data.mrAppRepDataUrl + "/" + this.campaign_id;
          }
          let dataAppend = new FormData();
  
          for (var key in this.form) {
              dataAppend.append(key, this.form[key]);
          }
  
          if (this.$route.name == "edit-rep") {
            dataAppend.append("_method", "put");
          }
          const data = await this.postRequest(url, dataAppend);
          if (data.status) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title: data.message,
            });
            this.$router.push("/field-rep-app/rep");
          }
        } catch (err) {
          console.log(err);
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: err.data ? err.data.message : "Please try again!",
          });
        }
        this.$store.commit("loader/updateStatus", false);
      },
    },
    watch: {
      currentPage: {
        handler: function (value) {
          if (typeof value != undefined) {
            this.params = `&page=${value}`;
            this.fetchData(this.activeTab);
            this.pageNo = this.currentPage;
          }
        },
      },
      // activeTab(v) {
        // if (v) this.tabActive();
      // },
      "form.content_type"(v) {
        if (v == "pop_up") {
          this.form.ad_position = 1;
        }
      },
    },
    created() {
      if (
        this.$route.name == "add-rep" ||
        this.$route.name == "edit-rep"
      ) {
        if (this.$route.name == "edit-rep") {
          this.fetchReps(this.$route.params.id);
        }
      } else {
        this.fetchData(this.activeTab);
      }
      this.fields.map((item) => {
        this.table_header.push(item.key);
      });
      // this.tabActive();
    },
  };
  